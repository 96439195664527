<template>
  <div id="ProfilePage">
    <Sidebar :isUserSidebar="false" />
    <div id="PageStart" ref="PageStart" class="ProfileContent">
      <!-- <p class="PageHeader">Добро пожаловать, Константин</p> -->
      <div class="ProfileBlocks">
        <div class="profileBlock BlockColumn1">
          <BetaOrgProfileCard
            :user="!isViewMode ? user : viewModeUserCard"
            :editProfileHandle="
              () => {
                modal_edit_profile_show = true;
                fillEditProfileModal();
              }
            "
            :isViewMode="isViewMode"
            :key="isViewMode"
          />
          <template v-if="!isViewMode">
            <div class="WorkWithProjects">
              <span class="Title">Работа с проектами</span>
              <ButtonGradientBorder
                ButtonText="Добавить проект"
                @click.native="modal_add_project_show = true"
              />
            </div>
          </template>
          <!-- <div class="WaitingChangesWrapper">
            <span class="WaitingYourChanges">Ожидают ваших изменений: 0</span>
            <span class="noclasshas"
              >Сервис для бронирования общих ресурсов</span
            >
            <span class="noclasshas">Тестовый проект 1</span>
            <span class="noclasshas">Тестовый проект 2</span>
            <span class="noclasshas">Тестовый проект 3</span>
            <span class="noclasshas">Тестовый проект 4</span>
          </div> -->
        </div>
        <div class="profileBlock BlockColumn2">
          <!-- <div class="SearchPanel">
            <div class="LeftSide">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19 7.17071C20.1652 7.58254 21 8.69378 21 10C21 11.3062 20.1652 12.4175 19 12.8293V19C19 19.5523 18.5523 20 18 20C17.4477 20 17 19.5523 17 19V12.8293C15.8348 12.4175 15 11.3062 15 10C15 8.69378 15.8348 7.58254 17 7.17071V5C17 4.44772 17.4477 4 18 4C18.5523 4 19 4.44772 19 5V7.17071ZM15 15C15 16.3062 14.1652 17.4175 13 17.8293V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V17.8293C9.83481 17.4175 9 16.3062 9 15C9 13.6938 9.83481 12.5825 11 12.1707V5C11 4.44772 11.4477 4 12 4C12.5523 4 13 4.44772 13 5V12.1707C14.1652 12.5825 15 13.6938 15 15ZM7 7.17071C8.16519 7.58254 9 8.69378 9 10C9 11.3062 8.16519 12.4175 7 12.8293V19C7 19.5523 6.55228 20 6 20C5.44772 20 5 19.5523 5 19V12.8293C3.83481 12.4175 3 11.3062 3 10C3 8.69378 3.83481 7.58254 5 7.17071V5C5 4.44772 5.44772 4 6 4C6.55228 4 7 4.44772 7 5V7.17071ZM6 11C6.55228 11 7 10.5523 7 10C7 9.44772 6.55228 9 6 9C5.44772 9 5 9.44772 5 10C5 10.5523 5.44772 11 6 11ZM18 11C18.5523 11 19 10.5523 19 10C19 9.44772 18.5523 9 18 9C17.4477 9 17 9.44772 17 10C17 10.5523 17.4477 11 18 11ZM12 16C12.5523 16 13 15.5523 13 15C13 14.4477 12.5523 14 12 14C11.4477 14 11 14.4477 11 15C11 15.5523 11.4477 16 12 16Z"
                  fill="white"
                />
              </svg>
              <span>Фильтры</span>
              <span class="Notif">
                0
              </span>
            </div>

            <SearchInput class="SearchInputComponent" />
          </div> -->
          <div
            class="notificationText"
            v-if="this.projects.length === 0 && view_mode == 'projects'"
          >
            Здесь пусто, пока вы не добавили свой первый проект.
          </div>

          <div class="ProjectsList" v-if="view_mode == 'projects'">
            <OrgProjectCard
              v-for="project in projects"
              :key="project"
              :project="project"
              :removeProjectHandle="
                (id) => {
                  editProjectForm.project.id = id;
                  modal_delete_project_show = true;
                }
              "
              :editProjectHandle="
                () => {
                  editProjectForm.project.id = project.id;

                  editProjectForm.updateData.name = project.name;
                  editProjectForm.updateData.description = project.description;
                  modal_edit_project_show = true;
                }
              "
              :addJobHandle="addJobHandle"
              :viewTasksListHandle="showTasksList"
              :isViewMode="isViewMode"
            />
          </div>

          <div class="TasksList" v-if="view_mode == 'tasks'">
            <div class="TLHead">
              <span class="TasksListHeader"> {{ ViewTasksHeader }} </span>
              <ButtonGradientBorder
                class="TLBBack"
                ButtonText="Назад"
                @click.native="view_mode = 'projects'"
              />
            </div>

            <div class="TasksWrapper">
              <div
                class="TaskCard"
                v-for="(task, tindx) in JobsList"
                :key="`${tindx}${task.id}`"
              >
                <div class="JobStatusBlock noselect">
                  <span
                    class="JobStatus"
                    :style="
                      `border-color: ${
                        getJobLatestStatus(task).show_color
                      }; color: ${getJobLatestStatus(task).show_color};`
                    "
                    >{{ getJobLatestStatus(task).show_name }}</span
                  >
                  <span
                    class="JobStatusHasComment"
                    v-if="getJobLatestStatus(task).name == 'Отклонено'"
                    @click="
                      modal_view_job_comments_show = true;
                      modal_view_job_comments_task = task;
                    "
                    >Посмотреть замечания</span
                  >
                </div>

                <div class="UpperContent">
                  <div class="UPContainer">
                    <span class="DirectionContainer">{{
                      directionsList(task?.sections) == ""
                        ? "Направление и сложность будут установлены администратором"
                        : directionsList(task?.sections)
                    }}</span>
                    <div
                      class="Difficulties"
                      v-if="task?.section?.name != null"
                    >
                      <div
                        class="DifficultFilled"
                        v-for="x in getTaskDifficult(task.level)"
                        :key="x"
                      ></div>

                      <div
                        class="DifficultEmpty"
                        v-for="x in 3 - getTaskDifficult(task.level)"
                        :key="x"
                      ></div>
                    </div>
                  </div>
                  <span>{{ task.name }}</span>
                </div>

                <div class="BottomContent">
                  <div class="Left">
                    <div class="IconPeoples"></div>
                    <span
                      >{{
                        task.JobFollowers != null
                          ? task.JobFollowers.length
                          : "0"
                      }}
                      {{
                        correctTaskFollowersName(
                          task.JobFollowers != null
                            ? task.JobFollowers.length
                            : 0
                        )
                      }}</span
                    >
                  </div>
                  <div class="Right">
                    <template v-if="!isViewMode">
                      <div
                        class="HButton HBSettings"
                        @click="
                          view_mode_selected_task_id = task.id;
                          editTaskHandle(task);
                        "
                      ></div>
                      <div
                        class="HButton HBTrash"
                        @click="removeJobFromList(task.id)"
                      ></div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ModalWrapper" v-show="modal_add_project_show">
      <div class="Modal">
        <div
          class="CloseModalButton"
          @click="
            modal_add_project_show = false;
            this.clearFormFields(this.addProjectForm);
          "
        ></div>

        <div class="ModalHeader noselect">
          <div class="Buttons">
            <div class="Tab Active">
              <span>Создание проекта</span>
            </div>
          </div>
        </div>
        <div class="InputWrapper">
          <span class="Label">Название проекта</span>
          <input type="text" v-model="addProjectForm.name" />
        </div>

        <div class="InputWrapper">
          <span class="Label">Описание проекта</span>
          <div
            class="QEditor QEditorFixHeight"
            style="position: relative; width: 400px; height: 400px;"
          >
            <QuillEditor
              ref="QuillEditorAPD"
              toolbar="full"
              theme="snow"
              contentType="html"
              :content="addProjectForm.description"
              @textChange="
                handleEditorEditChanges($event, 'APD', 'QuillEditorAPD')
              "
              @ready="handleEditorEditReady('APD', 'QuillEditorAPD')"
            />
          </div>
          <!-- <textarea v-model="addProjectForm.description" /> -->
        </div>
        <ButtonGradient
          class="ActionButton"
          @click.native="addProject()"
          ButtonText="Создать проект"
        />
      </div>
    </div>

    <div class="ModalWrapper" v-show="modal_delete_project_show">
      <div class="Modal">
        <div
          class="CloseModalButton"
          @click="modal_delete_project_show = false"
        ></div>

        <div class="ModalHeader noselect">
          <div class="Buttons">
            <div class="Tab Active">
              <span>Удаление проекта</span>
            </div>
          </div>
        </div>

        <div class="SimpleText">
          <span>Вы уверены что хотите удалить проект?</span>
        </div>

        <ButtonGradient
          class="ActionButton"
          ButtonText="Удалить"
          @click="
            handleDeleteProject();
            modal_delete_project_show = false;
          "
        />
      </div>
    </div>

    <div class="ModalWrapper" v-show="modal_edit_project_show">
      <div class="Modal">
        <div
          class="CloseModalButton"
          @click="modal_edit_project_show = false"
        ></div>

        <div class="ModalHeader noselect">
          <div class="Buttons noselect">
            <div class="Tab Active ">
              <span>Редактировать проект</span>
            </div>
          </div>
        </div>

        <div class="EditContent ContentWrapper">
          <!-- <div class="PhotoWrapper noselect">
            <div class="Photo">
              <div
                class="UploadedPhoto"
                :style="
                  uploadedImage(
                    'https://cdn.bhdw.net/im/ultravoilet-holo-wallpaper-38703_w635.webp'
                  )
                "
              ></div>
              <div class="PhotoPlaceholder"></div>
            </div>

            <span>Изменить фото</span>
          </div> -->

          <div class="InputWrapper">
            <span class="Label">Название проекта</span>
            <input type="text" v-model="editProjectForm.updateData.name" />
          </div>

          <div class="InputWrapper">
            <span class="Label">Описание проекта</span>
            <div
              class="QEditor QEditorFixHeight"
              style="position: relative; width: 400px; height: 400px;"
            >
              <QuillEditor
                ref="QuillEditorEPD"
                toolbar="full"
                theme="snow"
                contentType="html"
                :content="editProjectForm.updateData.description"
                @textChange="
                  handleEditorEditChanges($event, 'EPD', 'QuillEditorEPD')
                "
                @ready="handleEditorEditReady('EPD', 'QuillEditorEPD')"
              />
            </div>
            <!-- <textarea v-model="editProjectForm.updateData.description" /> -->
          </div>

          <!-- <div class="InputWrapper">
            <span class="Label">Ссылки на правовую документацию</span>
            <textarea />
          </div> -->

          <!-- <div class="SplittedContent">
            <div class="Column">
              <span class="ColumnLabel">Контактная информация</span>
              <div class="CContent">
                <div class="InputWrapper">
                  <span class="Label">Email</span>
                  <input type="text" />
                </div>

                <div class="InputWrapper">
                  <span class="Label">Телефон</span>
                  <input type="text" />
                </div>
              </div>
            </div>
            <div class="Column">
              <span class="ColumnLabel">Доп. материалы</span>
              <div class="CContent">
                <div class="UploadFile noselect">
                  <div class="UploadFileTip">
                    <div class="UploadPlaceholder"></div>
                    <span class="UploadPlaceholderTip">Загрузка файлов</span>
                    <span
                      >Нажмите или перетащите ваш файл формата .zip в эту
                      область</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>

        <ButtonGradient
          class="ActionButton"
          @click.native="editProject(project)"
          ButtonText="Сохранить"
        />
      </div>
    </div>

    <div class="ModalWrapper" v-show="modal_edit_profile_show">
      <div class="Modal">
        <div
          class="CloseModalButton"
          @click="modal_edit_profile_show = false"
        ></div>

        <div class="ModalHeader noselect">
          <div class="Buttons noselect">
            <div
              :class="
                `Tab ${
                  modal_edit_profile_tab_active == 'Profile' ? 'Active' : ''
                } `
              "
              @click="modal_edit_profile_tab_active = 'Profile'"
            >
              <span>Пользователь</span>
            </div>

            <div
              :class="
                `Tab ${
                  modal_edit_profile_tab_active == 'Organization'
                    ? 'Active'
                    : ''
                } `
              "
              @click="modal_edit_profile_tab_active = 'Organization'"
            >
              <span>Организация</span>
            </div>
          </div>
        </div>

        <div
          class="ProfileEditContent ContentWrapper"
          v-show="modal_edit_profile_tab_active == 'Profile'"
        >
          <!-- <div class="PhotoWrapper noselect">
            <div class="Photo">
              <div class="UploadedPhoto" :style="uploadedImage('#')"></div>
              <div class="PhotoPlaceholder"></div>
            </div>

            <span>Изменить фото</span>
          </div> -->

          <div class="InputWrapper">
            <span class="Label">Имя</span>
            <input type="text" v-model="editProfileForm.user.name" />
          </div>

          <div class="InputWrapper">
            <span class="Label">Фамилия</span>
            <input type="text" v-model="editProfileForm.user.surname" />
          </div>

          <div class="InputWrapper">
            <span class="Label">Отчество</span>
            <input type="text" v-model="editProfileForm.user.patronymic" />
          </div>

          <div class="InputWrapper">
            <span class="Label">Email</span>
            <input type="text" v-model="editProfileForm.user.email" />
          </div>

          <div class="InputWrapper">
            <span class="Label">Телефон</span>
            <input type="text" v-model="editProfileForm.user.tel" />
          </div>

          <div class="InputWrapper">
            <span class="Label">Пароль</span>
            <input type="text" />
          </div>

          <div class="InputWrapper">
            <span class="Label">Повторите пароль</span>
            <input type="text" />
          </div>
        </div>

        <div
          class="OrganizationEditContent ContentWrapper"
          v-show="modal_edit_profile_tab_active == 'Organization'"
        >
          <div class="InputWrapper">
            <span class="Label">Наименование</span>
            <input type="text" v-model="editProfileForm.company.name" />
          </div>
        </div>

        <ButtonGradient
          class="ActionButton"
          ButtonText="Сохранить"
          @click="saveProfileInfo()"
        />
      </div>
    </div>

    <div class="ModalWrapper" v-show="modal_add_job_show">
      <div class="Modal">
        <div class="CloseModalButton" @click="modal_add_job_show = false"></div>

        <div class="ModalHeader noselect">
          <div class="Buttons noselect">
            <div class="Tab Active ">
              <span>{{
                modal_add_job_mode == "add"
                  ? "Добавление задачи"
                  : "Редактирование задачи"
              }}</span>
            </div>
          </div>
        </div>

        <div class="EditContent ContentWrapper">
          <div class="InputWrapper">
            <span class="Label">Название задачи</span>
            <input
              type="text"
              v-model="addJobForm.name"
              placeholder="Укажите кратко суть задачи"
            />
          </div>

          <div class="InputWrapper">
            <span class="Label">Описание задачи</span>

            <div
              class="QEditor QEditorFixHeight"
              style="position: relative; width: 400px; height: 400px;"
            >
              <QuillEditor
                ref="QuillEditorAJD"
                toolbar="full"
                theme="snow"
                contentType="html"
                :content="addJobForm.description"
                @textChange="
                  handleEditorEditChanges($event, 'AJD', 'QuillEditorAJD')
                "
                @ready="handleEditorEditReady('AJD', 'QuillEditorAJD')"
              />
            </div>
            <!-- <textarea
              rows="5"
              v-model="addJobForm.description"
              placeholder="Распишите подробно, что необходимо сделать"
            /> -->
          </div>

          <div class="InputWrapper">
            <span class="Label">Цель задачи</span>
            <textarea
              v-model="addJobForm.purpose"
              placeholder="Какую цель нужно реализовать?"
            />
          </div>

          <div class="InputWrapper">
            <span class="Label">Технологический стек</span>
            <input
              type="text"
              v-model="addJobForm.techStack"
              placeholder="Пример: Python, Bitrix, PHP, MySQL.."
            />
          </div>

          <div class="InputWrapper">
            <span class="Label"
              >Интеграция: необходимость получения данных из других систем</span
            >
            <textarea
              v-model="addJobForm.integrations"
              placeholder="Есть ли ограничения по реализации?"
            />
          </div>

          <div class="InputWrapper">
            <span class="Label">Желаемый результат</span>
            <textarea
              v-model="addJobForm.result"
              placeholder="Опишите желаемый результат"
            />
          </div>

          <div class="InputWrapper">
            <span class="Label">Ожидаемый эффект</span>
            <textarea
              v-model="addJobForm.effect"
              placeholder="Опишите желаемый эффект от решения задачи"
            />
          </div>

          <div class="InputWrapper">
            <span class="Label">Выберите сложность задачи</span>
            <el-select
              v-model="addJobForm.level"
              class="RDSelectField"
              placeholder="Выберите"
              size="large"
            >
              <el-option
                v-for="item in DifficultyLevels"
                :key="item.name"
                :label="item.name"
                :value="item.name"
              />
            </el-select>
          </div>

          <div class="InputWrapper">
            <span class="Label">Укажите направление задачи</span>
            <el-select
              :disabled="clearDirectionList"
              v-model="addJobForm.sectionId"
              class="RDSelectField"
              placeholder="Выберите"
              size="large"
            >
              <el-option
                :disabled="clearDirectionList"
                v-for="item in SectionsList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
            <div class="checkbox_agreement">
              <input type="checkbox" v-model="addJobForm.iDontKnowWhatChoose" />
              <label for="advert_checkbox">Не знаю, что выбрать</label>
            </div>
          </div>
        </div>

        <template v-if="modal_add_job_mode == 'add'">
          <ButtonGradient
            class="ActionButton"
            @click.native="createJob"
            ButtonText="Добавить"
          />
        </template>
        <template v-if="modal_add_job_mode == 'edit'">
          <ButtonGradient
            class="ActionButton"
            @click.native="editJob"
            ButtonText="Сохранить изменения"
          />
        </template>
      </div>
    </div>

    <el-dialog
      v-model="modal_view_job_comments_show"
      title="Замечания от модератора"
      custom-class="ORG_VIEW_COMMENT_DIALOG"
    >
      <span class="DEFAULT_ORG_VIEW_TEXT">{{
        modal_view_job_comments_task?.Project_Job_Statuses[
          modal_view_job_comments_task?.Project_Job_Statuses.length - 1
        ]?.comment
      }}</span>
      <template #footer>
        <template v-if="!isViewMode">
          <span
            class="DEFAULT_ORG_VIEW_EDIT_BUTTON noselect"
            @click="
              modal_view_job_comments_show = false;
              view_mode_selected_task_id = modal_view_job_comments_task.id;
              editTaskHandle(modal_view_job_comments_task);
            "
            >Редактировать задачу</span
          >
          <span
            class="DEFAULT_ORG_VIEW_CLOSE_BUTTON noselect"
            @click="modal_view_job_comments_show = false"
            >Изменю позже</span
          >
        </template>
        <template v-else>
          <span
            class="DEFAULT_ORG_VIEW_EDIT_BUTTON noselect"
            @click="modal_view_job_comments_show = false"
            >Закрыть</span
          >
        </template>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped>
@import "./../../../node_modules/@vueup/vue-quill/dist/vue-quill.core.prod.css";
@import "./../../../node_modules/@vueup/vue-quill/dist/vue-quill.snow.prod.css";

/* .Modal {
  width: 35% !important;
} */
#ProfilePage {
  position: relative;
  width: 100%;
  height: 100vh;

  top: 0%;
  left: 0%;

  margin: 0%;
  padding: 0%;

  display: flex;
  flex-direction: row;

  overflow: hidden;

  background-image: radial-gradient(
    123.22% 129.67% at 100.89% -5.6%,
    #201d47 0%,
    #17153a 100%
  );
  background-position: 50% 50%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: auto 120%;
}

#ProfilePage > .ProfileContent {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  padding: 23px 32px 23px 21px;
}

#ProfilePage > .ProfileContent > .ProfileBlocks {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;

  margin: 0%;
  padding: 0%;
}

#ProfilePage > .ProfileContent > .ProfileBlocks > .profileBlock {
  position: relative;
  height: 100%;
}

#ProfilePage > .ProfileContent > .PageHeader {
  position: relative;
  width: 100%;

  margin-bottom: 32px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;

  /* identical to box height */

  /* students/white */
  color: #e8e7ec;
}

.BlockColumn1,
.BlockColumn2 {
  flex-shrink: 0;
}
.BlockColumn1 {
  display: flex;
  flex-direction: column;
  width: 398px;
  margin-right: 32px;
  gap: 32px;
}
.BlockColumn2 {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
}
.WaitingChangesWrapper {
  max-height: 350px;
}
</style>

<style scoped>
@media (max-width: 480px) {
  #ProfilePage .BlockColumn1 {
    height: fit-content !important;
  }

  #ProfilePage .BlockColumn2 {
    margin-bottom: 70px !important;
  }

  #ProfilePage .ProfileBlocks .BlockColumn1 > *:nth-child(1) {
    height: fit-content;
    width: 100% !important;
    margin-bottom: 0px !important;
  }
  #ProfilePage .ProfileBlocks .BlockColumn2 > *:nth-child(2) {
    margin-bottom: 80px;
  }

  .ProjectsList {
    flex-wrap: wrap;
    flex-shrink: 0;
    flex-direction: column;
    height: fit-content;
    margin-bottom: 5rem;
  }
  .ProjectsList .Card {
    flex-basis: 100%;
  }
  .WaitingChangesWrapper {
    display: none !important;
  }

  #ProfilePage .ModalWrapper {
    height: 100svh;
  }
  #ProfilePage .ModalWrapper > .Modal {
    padding: 16px 24px;
    max-width: 95%;
    max-height: calc(95% - 60px);

    margin-bottom: auto;
    margin-top: 20px;
  }
  #ProfilePage .Modal > .ModalHeader {
    padding: 0px;
    overflow-x: hidden;
    overflow-y: visible;
    flex-shrink: 0;
    padding-right: 35px;
  }

  #ProfilePage .Modal > .ModalHeader > .Buttons {
    padding-bottom: 20px;
    justify-content: flex-start;
    overflow-x: auto;
  }

  #ProfilePage .ModalWrapper > .Modal > .CloseModalButton {
    width: 30px;
    height: 30px;
    right: 20px !important;
    top: 20px !important;

    background-size: 30px 30px;
  }

  #ProfilePage .Buttons > .Tab > span {
    font-size: 12px;
    line-height: 18px;
  }

  #ProfilePage .ModalHeader > .Buttons > .Tab {
    padding: 8px 24px;
  }
}

@media (max-width: 992px) {
  #ProfilePage .ModalWrapper {
    height: 100svh;
  }
  #ProfilePage .ModalWrapper > .Modal {
    padding: 16px 24px;
    max-width: 95%;
    max-height: calc(95% - 60px);

    margin-bottom: auto;
    margin-top: 20px;
  }

  #ProfilePage .ModalWrapper > .Modal > .CloseModalButton {
    width: 30px;
    height: 30px;
    right: 30px;
    top: 30px;

    background-size: 30px 30px;
  }

  .ProfileCard {
    height: fit-content !important;
  }
  .ProjectsList {
    flex-wrap: wrap;
    flex-shrink: 0;
    flex-direction: column;
    height: fit-content;
    margin-bottom: 5rem;
  }
  .ProjectsList .Card {
    flex-basis: 100%;
  }
  .WaitingChangesWrapper {
    display: none !important;
  }

  #ProfilePage .ProfileBlocks .BlockColumn2 > *:nth-child(2) {
    margin-bottom: 80px;
  }

  #ProfilePage {
    overflow: auto;
  }

  #ProfilePage > .ProfileContent > .ProfileBlocks {
    flex-wrap: wrap;
    padding: 0% 32px 0% 21px;

    row-gap: 20px;
    column-gap: 40px;
  }

  #ProfilePage > .ProfileContent > .ProfileBlocks .profileBlock {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;

    padding: 0%;
  }

  #ProfilePage > .ProfileContent {
    overflow: auto;
    padding: 23px 0px 23px 0px;
  }

  .BlockColumn1,
  .BlockColumn2 {
    margin-right: 0px;
    flex-shrink: 1;
  }
  .BlockColumn1 {
    flex-direction: column;
    width: 100%;
    max-width: 100%;

    height: fit-content !important;

    row-gap: 40px;
    column-gap: 40px;

    order: 1;
  }
  .BlockColumn2 {
    flex-direction: column;
    width: 100%;
    max-width: 100%;

    height: fit-content !important;

    order: 3;
  }
  .BlockColumn3 {
    width: 100%;
    max-width: 100%;

    height: auto !important;
    max-height: 400px;

    padding-bottom: 21px;
    order: 2;
  }

  #ProfilePage .BlockColumn1 > *:nth-child(1) {
    height: 150px;
    width: 100% !important;
    margin-bottom: 00px !important;
  }

  #ProfilePage .BlockColumn1 > *:nth-child(2) {
    display: none;
  }

  #ProfilePage .BlockColumn2 > *:nth-child(2) {
    flex-shrink: 0;
    padding-bottom: 23px;
    height: 600px;

    flex-basis: content;
    overflow: auto;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  .ProfileCard {
    width: 100%;
  }
  .BlockColumn2 {
    flex-shrink: 1;
  }
  .BlockColumn1 {
    width: calc(50% - 16px);
    max-width: calc(50% - 16px);
    margin-right: 32px;
  }
  .BlockColumn2 {
    width: calc(50% - 16px);
    max-width: calc(50% - 16px);
  }

  .BlockColumn2 .ProjectsList > * {
    width: calc(100%);
  }

  .BlockColumn2 > *:not(:nth-last-child(2)) {
    flex-basis: content;
    overflow-y: auto;
  }

  .ProfileBlocks .SearchPanel > .LeftSide > span {
    font-size: 12px;
  }
  .ProfileBlocks .SearchPanel > .LeftSide > svg {
    width: 16px;
    height: 16px;
  }
  .ProfileBlocks .SearchPanel {
    gap: 24px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .BlockColumn2 {
    flex-shrink: 1;
  }
  .BlockColumn1 {
    width: 400px;
    max-width: 400px;
    margin-right: 32px;
  }
  .BlockColumn2 {
    width: 100%;
    max-width: 1322px;
  }

  .BlockColumn2 .ProjectsList > * {
    width: calc(100%);
  }

  .BlockColumn2 > *:not(:nth-last-child(2)) {
    flex-basis: content;
    overflow-y: auto;
  }
}
@media (min-width: 1400px) {
  .BlockColumn2 {
    flex-shrink: 1;
  }
  .BlockColumn1 {
    width: 400px;
    max-width: 400px;
    margin-right: 32px;
  }
  .BlockColumn2 {
    width: 100%;
    max-width: 1322px;
  }

  .BlockColumn2 > *:not(:nth-last-child(2)) {
    flex-basis: content;
    overflow-y: auto;
  }
}
</style>

<style scoped>
.JobStatusBlock > * {
  margin-top: auto;
  margin-bottom: auto;
}
.JobStatusBlock {
  position: relative;

  margin-bottom: 20px;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}
.JobStatusBlock > .JobStatusHasComment {
  position: relative;
  display: block;

  cursor: pointer;

  width: fit-content;
  height: fit-content;

  color: #eb5757;
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}
.JobStatusBlock > .JobStatus {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  padding: 5px 8px;

  color: #eb5757;
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  border-radius: 4px;
  border: 1px solid var(--Red, #eb5757);
}

.BlockColumn1 > * {
  flex-shrink: 0;
}

.ProfileBlock {
  position: relative;

  width: 100%;
  height: 237px;

  border-radius: 20px;
  background: rgba(32, 30, 72, 0.7);
  /* shadow */
  box-shadow: 8px 0px 8px 0px rgba(31, 18, 35, 0.15);
}

.WorkWithProjects {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  padding: 28px 32px;

  width: 100%;
  height: fit-content;

  border-radius: 20px;
  background: rgba(32, 30, 72, 0.7);
  /* shadow */
  box-shadow: 8px 0px 8px 0px rgba(31, 18, 35, 0.15);
}
.WorkWithProjects > .Title {
  color: #e8e7ec;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.BlockColumn1 > .WaitingChangesWrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  padding: 28px 32px;

  width: 100%;

  height: 100%;

  border-radius: 20px;
  background: rgba(32, 30, 72, 0.7);
  /* shadow */
  box-shadow: 8px 0px 8px 0px rgba(31, 18, 35, 0.15);

  flex-shrink: 1;

  overflow-y: auto;
}
.BlockColumn1 > .WaitingChangesWrapper > .WaitingYourChanges {
  position: relative;

  padding: 4px 8px;

  width: fit-content;
  height: fit-content;

  color: #12aee3;
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  border-radius: 4px;
  border: 1px solid #12aee3;
}
.BlockColumn1 > .WaitingChangesWrapper > .noclasshas {
  color: #e8e7ec;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.SearchPanel {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 16px 32px;

  width: 100%;
  height: fit-content;

  border-radius: 20px;
  background: rgba(32, 30, 72, 0.7);
  /* shadow */
  box-shadow: 8px 0px 8px 0px rgba(31, 18, 35, 0.15);
}
.SearchPanel > * {
  margin-top: auto;
  margin-bottom: auto;
}
.SearchPanel > .LeftSide {
  position: relative;

  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
}
.SearchPanel > .LeftSide > * {
  margin-top: auto;
  margin-bottom: auto;
}
.SearchPanel > .LeftSide > span {
  color: #e8e7ec;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.SearchPanel > .LeftSide > .Notif {
  position: relative;

  padding: 2px 4px;

  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px; /* 130% */

  width: auto;
  height: 17px;

  aspect-ratio: 1 / 1;

  border-radius: 8px;
  background: #665bfd;

  text-align: center;
}

.ProjectsList {
  position: relative;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 32px;

  width: 100%;
  height: 100%;
}

.BlockColumn2 > .TasksList > .TLHead > * {
  margin-top: auto;
  margin-bottom: auto;
}
.BlockColumn2 > .TasksList > .TLHead {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
}
.BlockColumn2 > .TasksList {
  position: relative;

  padding: 30px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;
  flex-basis: auto;

  width: 100%;
  height: 100%;

  border-radius: 20px;
  background: rgba(31, 30, 72, 0.6);
}
.BlockColumn2 > .TasksList .TasksListHeader {
  color: #e8e7ec;

  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.TasksList > .TasksWrapper {
  position: relative;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: content;
  justify-content: flex-start;
  gap: 30px 15px;

  overflow-y: auto;

  width: 100%;
  height: 100%;
}

.TaskCard {
  position: relative;

  padding: 20px 30px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: calc((100% - 60px) / 3);
  height: auto;
  min-height: 180px;

  border-radius: 20px;
  background: #1f1e48;
}
.TaskCard > .UpperContent {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}
.TaskCard > .UpperContent > span {
  color: #fff;

  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%;
}
.TaskCard > .UpperContent > .UPContainer {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}
.TaskCard > .UpperContent > .UPContainer > * {
  margin-top: auto;
  margin-bottom: auto;
}
.TaskCard > .UpperContent > .UPContainer > .DirectionContainer {
  position: relative;
  display: block;

  padding: 4px 8px;

  width: fit-content;
  height: fit-content;

  color: #fff;
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  border-radius: 4px;
  background: #22214d;
}

.Difficulties {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.ProjectsList > * {
  width: calc(50% - 16px);
}

.DifficultEmpty {
  position: relative;
  display: block;

  width: 16px;
  height: 16px;

  background: url("./../../assets/img/profile/ProfileProjectCard/DifficultIconPlaceholder.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.DifficultFilled {
  position: relative;
  display: block;

  width: 16px;
  height: 16px;

  background: url("./../../assets/img/profile/ProfileProjectCard/DifficultIcon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.TaskCard > .BottomContent {
  position: relative;

  margin-top: auto;
  padding-top: 10px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.TaskCard > .BottomContent > *,
.TaskCard > .BottomContent > .Left > * {
  margin-top: auto;
  margin-bottom: auto;
}

.TaskCard > .BottomContent > .Left,
.TaskCard > .BottomContent > .Right {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
}
.TaskCard > .BottomContent > .Left > span {
  color: #fff;

  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.IconPeoples {
  position: relative;
  display: block;

  width: 16px;
  height: 12px;

  background: url("./../../assets/img/Landing/Peoples16x12.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.HButton {
  position: relative;
  display: block;

  cursor: pointer;

  width: 15px;
  height: 15px;
}

.HButton.HBSettings {
  background: url("./../../assets/img/profile/edit_icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.HButton.HBTrash {
  background: url("./../../assets/img/profile/delete_icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.TLBBack {
  width: 200px;
}
</style>

<style scoped>
input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: transparent;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.1em solid #6e6a9a;
  border-radius: 4px;
  padding: 0.7rem;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;

  margin-right: 0.5rem;
}

input[type="checkbox"]:checked {
  background-image: linear-gradient(221.26deg, #c548ab -3.4%, #635bff 101.08%);
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #fff;

  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.checkbox_agreement {
  color: #fff;
  font-family: "Montserrat";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.3rem;
  align-items: center;
}

.notificationText {
  display: flex;
  align-items: center;
  margin: auto;

  position: relative;
  display: block;

  width: 100%;

  color: #fff;
  text-align: center;

  /* text */
  font-family: "Montserrat";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 155%; /* 23.25px */
}
.ModalWrapper {
  position: fixed;

  display: flex;

  width: 100%;
  height: 100vh;

  top: 0;
  left: 0;

  margin: 0;
  padding: 0;

  background-color: rgba(0, 0, 0, 0.75);
}

.ModalWrapper > .Modal {
  position: relative;

  padding: 28px 32px;

  width: fit-content;
  max-width: 95%;

  height: fit-content;
  max-height: 95%;

  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  border-radius: 20px;
  background: #201e48;

  overflow: auto;
}

.ModalWrapper > .Modal > .CloseModalButton {
  position: absolute;

  cursor: pointer;

  width: 40px;
  height: 40px;

  right: 32px;
  top: 32px;

  background: url("./../../assets/img/Landing/ModalNewCloseButton.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40px 40px;

  z-index: 10;
}

.Modal > .ActionButton {
  width: 100%;
}
.Modal > .SimpleText {
  position: relative;

  width: auto;
  height: fit-content;
}
.Modal > .SimpleText > span {
  position: relative;
  display: block;

  width: 100%;

  color: #fff;
  text-align: center;

  /* text */
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 23.25px */
}
.Modal > .ModalHeader {
  position: relative;

  padding: 0% 67px;

  width: auto;
  height: auto;

  display: flex;
}
.Modal > .ModalHeader > .Buttons {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 100%;
  height: fit-content;

  margin: 0% auto;
}
.ModalHeader > .Buttons > .Tab {
  position: relative;

  cursor: pointer;

  padding: 12px 32px;

  width: fit-content;
  height: fit-content;

  border-bottom: 1px solid rgba(102, 112, 128, 0.3);
}
.ModalHeader > .Buttons > .Tab.Active {
  border-bottom: 2px solid #e8e7ec;
}
.Buttons > .Tab > span {
  color: #667080;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
}
.Buttons > .Tab.Active > span {
  color: #e8e7ec;
  font-weight: 600;
}

.Modal > .ContentWrapper {
  position: relative;

  width: auto;
  height: auto;

  padding: 0px 8px 6px 6px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  overflow: auto;
}

.PhotoWrapper {
  position: relative;

  cursor: pointer;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.PhotoWrapper > span {
  position: relative;

  display: block;

  width: fit-content;
  height: fit-content;

  margin: 0% auto;

  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.PhotoWrapper > .Photo {
  position: relative;

  display: block;

  width: 170px;
  height: 110px;

  margin: 0% auto;
}

.PhotoWrapper > .Photo > .PhotoPlaceholder {
  position: absolute;
  display: block;

  width: 100%;
  height: 100%;

  top: 0;
  left: 0;

  background: url("./../../assets/img/Landing/PhotoPlaceholder.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  z-index: 10;

  border-radius: 6px;
}

.PhotoWrapper > .Photo > .UploadedPhoto {
  position: absolute;
  display: block;

  width: 100%;
  height: 100%;

  top: 0;
  left: 0;

  z-index: 15;

  border-radius: 6px;
}

.EditContent > .SplittedContent {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 32px;
}
.EditContent > .SplittedContent > .Column {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  width: calc(50% - 16px);
}
.EditContent > .SplittedContent > .Column > .ColumnLabel {
  color: #fff;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.EditContent > .SplittedContent > .Column > .CContent {
  position: relative;

  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
}

.SplittedContent > .Column > .CContent > .UploadFile {
  position: relative;

  cursor: pointer;

  display: flex;

  padding: 25px 35px;

  width: 100%;
  height: fit-content;

  border: 1px dashed #413e74;

  border-radius: 6px;
  background: linear-gradient(
      245deg,
      rgba(41, 37, 88, 0.54) 0%,
      rgba(38, 35, 83, 0.87) 48.68%,
      rgba(40, 38, 89, 0.5) 99.42%
    ),
    rgba(65, 62, 116, 0.01);
}

.UploadFile > .UploadFileTip {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 10px;

  width: fit-content;
  height: fit-content;

  margin: auto;
}
.UploadFile > .UploadFileTip > * {
  margin-left: auto;
  margin-right: auto;
}
.UploadFile > .UploadFileTip > .UploadPlaceholder {
  position: relative;
  display: block;

  width: 58px;
  height: 48px;

  top: 0;
  left: 0;

  background: url("./../../assets/img/Landing/UploadPlaceholder.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  z-index: 10;
}
.UploadFile > .UploadFileTip > .UploadPlaceholderTip {
  position: relative;
  display: block;

  margin-bottom: 8px;

  width: fit-content;
  height: fit-content;

  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
}
.UploadFile > .UploadFileTip > span:not(.UploadPlaceholderTip) {
  position: relative;
  display: block;

  width: fit-content;
  max-width: 180px;
  height: fit-content;

  color: #c8c3de;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.2px;
}

.Modal .InputWrapper {
  position: relative;

  width: auto;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
}

.Modal .InputWrapper > .Label {
  color: #e8e7ec;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.28px;
}

.InputWrapper > input,
.InputWrapper > textarea {
  position: relative;

  width: 100%;
  height: 100%;

  padding: 14px 12px;

  border-radius: 4px;
  border: 1px solid #413e74;
  background: linear-gradient(
    269deg,
    rgba(41, 37, 88, 0.54) 4.06%,
    rgba(38, 35, 83, 0.87) 42.35%,
    rgba(40, 38, 89, 0.5) 99.53%
  );

  color: #c8c3de;
  text-overflow: ellipsis;

  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 23.25px */
}

.InputWrapper > input:focus-visible,
.InputWrapper > textarea:focus-visible {
  outline: 2px solid rgb(130, 111, 238) !important;
  border-radius: 3px;
}

.InputWrapper > textarea {
  min-height: 96px;
}
</style>

<script>
import BetaOrgProfileCard from "@/components/Organization/BetaProfileCard.vue";
import Sidebar from "@/components/Profile/Sidebar.vue";
import ButtonGradientBorder from "@/components/CustomElements/ButtonGradientBorder.vue";
import ButtonGradient from "@/components/CustomElements/ButtonGradient.vue";
import OrgProjectCard from "@/components/Organization/BetaProjectCard.vue";
import SearchInput from "@/components/CustomElements/SearchInput.vue";

import { mapGetters, mapMutations } from "vuex";
import { updateCompany, findCompanyById } from "@/api/company.js";
import { update as updateUser } from "@/api/user.js";
import {
  create as createProject,
  findByAuthorId,
  update as updateProject,
  deleteProject,
} from "@/api/n_project.js";

import {
  getAll,
  createJob,
  findAllJobByUser,
  removeJob,
  getAllDifficulties,
  updateJob,
} from "@/api/job.js";

import { createStatus } from "@/api/project_job_status.js";

import { getSections } from "@/api/competence.js";

import { QuillEditor } from "@vueup/vue-quill";

export default {
  data() {
    return {
      editProfileForm: {
        user: {
          name: "",
          surname: "",
          patronymic: "",
          email: "",
          tel: "",
        },
        company: {
          name: "",
        },
      },
      addProjectForm: {
        name: "",
        description: "",
      },
      editProjectForm: {
        updateData: {
          name: "",
          description: "",
        },
        project: {
          id: "",
        },
      },

      addJobForm: {
        name: "",
        description: "",
        level: "",
        sectionId: "",

        order: 0,

        iDontKnowWhatChoose: false,

        authorId: "",
        nProjectId: "",

        levels_array: [],
      },

      projects: [],
      DifficultyLevels: [],
      SectionsList: [],

      JobsList: [],
      ViewTasksHeader: "",

      education_show: false,
      education_show_component: "",

      modal_add_project_show: false,
      modal_delete_project_show: false,
      modal_edit_project_show: false,
      modal_edit_profile_show: false,
      modal_edit_profile_tab_active: "Profile",
      modal_add_job_show: false,
      modal_add_job_mode: "add",

      modal_view_job_comments_show: false,
      modal_view_job_comments_task: null,

      view_mode: "projects",
      view_mode_selected_project_id: null,
      view_mode_selected_task_id: null,

      isViewMode: false,
      viewModeUserCard: null,
    };
  },
  name: "OrgHome",
  components: {
    Sidebar,
    ButtonGradientBorder,
    OrgProjectCard,
    SearchInput,
    ButtonGradient,
    BetaOrgProfileCard,
    QuillEditor,
  },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),

    clearDirectionList() {
      if (this.addJobForm.iDontKnowWhatChoose) {
        this.addJobForm.sectionId = null;
      }

      return this.addJobForm.iDontKnowWhatChoose;
    },
  },
  async created() {
    await this.initialization();
  },
  mounted() {},
  methods: {
    directionsList(sects) {
      if (sects == null) {
        return "";
      }
      return sects.map((x) => x.name).join(", ");
    },
    async initialization() {
      if (this.$route.query?.viewID != null) {
        let _company_resp = null;

        this.isViewMode = true;

        try {
          _company_resp = await findCompanyById(this.$route.query?.viewID);
        } catch (e) {
          window.location.replace("/beta/o/profile");
        }

        // console.log(_company_resp);
        this.viewModeUserCard = {
          url_img: null,
          name: _company_resp.data.users[0].name,
          surname: _company_resp.data.users[0].surname,
          parent_company: [{ name: _company_resp.data.name }],
        };

        findByAuthorId(_company_resp.data.users[0].id).then((res) => {
          if (!res.error) this.projects = res.data;

          this.checkJobsStatuses();
        });
      } else {
        this.isViewMode = false;

        findByAuthorId(this.user.id).then((res) => {
          if (!res.error) this.projects = res.data;

          this.checkJobsStatuses();
        });
      }

      this.view_mode = "projects";

      getAllDifficulties().then((response) => {
        this.DifficultyLevels = response.data;
      });

      getSections().then((response) => {
        let sections = response.data;
        let competence = [];

        let self = this;

        let tags = new Set(sections.map((section) => section.tag));
        tags.forEach((tag) => {
          let filteredSections = sections
            .filter((section) => section.tag === tag)
            .map((section) => {
              return {
                name: section.name,
                id: section.id,
                levels: section.degrees
                  .flatMap((degree) => degree.levels.reverse())
                  .reverse()
                  .map((x) => x.id),
              };
            });
          competence.push({
            name: tag,
            counter: filteredSections.length,
            CBlock: filteredSections,
          });
        });

        if (competence.length >= 1) {
          self.SectionsList = competence[0].CBlock;
        }
      });
    },

    ...mapMutations("auth", ["SET_USER"]),
    fillEditProfileModal() {
      this.editProfileForm.user.name = this.user.name;
      this.editProfileForm.user.surname = this.user.surname;
      this.editProfileForm.user.patronymic = this.user.patronymic;
      this.editProfileForm.user.email = this.user.email;
      this.editProfileForm.user.tel = this.user.tel;
      this.editProfileForm.company.name = this.user.parent_company[0].name;
    },
    clearFormFields(fieldObject) {
      for (let key in fieldObject) {
        fieldObject[key] = "";
      }
    },
    updateProjectCards() {
      findByAuthorId(this.user.id).then((res) => {
        if (!res.error) {
          this.projects = res.data;
          if (
            this.view_mode != "projects" &&
            this.view_mode_selected_project_id !== null
          ) {
            this.showTasksList(this.view_mode_selected_project_id);
          }

          this.checkJobsStatuses();
        }
      });
    },
    editProject() {
      updateProject(
        this.editProjectForm.project.id,
        this.editProjectForm.updateData
      ).then((res) => {
        createStatus({
          status: "Ожидает",
          comment: null,
          nProjectId: this.editProjectForm.project.id,
        }).then(() => {
          this.updateProjectCards();
          this.modal_edit_project_show = false;
        });
      });
    },
    addProject() {
      let data = this.addProjectForm;
      data.authorId = this.user.id;

      createProject(data).then(() => {
        this.updateProjectCards();
        this.modal_add_project_show = false;
        this.clearFormFields(this.addProjectForm);
      });
    },
    handleDeleteProject() {
      deleteProject(this.editProjectForm.project.id).then(() => {
        this.editProjectForm.project.id = "";
        this.updateProjectCards();
      });
    },
    saveProfileInfo() {
      updateCompany(
        this.user.parent_company[0].id,
        this.editProfileForm.company
      ).then(() => {
        updateUser(this.user.id, this.editProfileForm.user).then((res) => {
          this.SET_USER(res.data);
        });
      });

      this.modal_edit_profile_show = false;
    },
    uploadedImage(url) {
      return `
      background: url("${url}");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      `;
    },

    addJobHandle(proj_id) {
      this.addJobForm = {
        name: "",
        description: "",
        level: "",
        sectionId: "",
        order: 0,

        result: "",
        effect: "",
        integrations: "",
        purpose: "",
        techStack: "",
        iDontKnowWhatChoose: false,

        authorId: this.user.id,
        nProjectId: proj_id,

        levels_array: [],
      };

      this.modal_add_job_show = true;
      this.modal_add_job_mode = "add";
    },
    editTaskHandle(task) {
      this.addJobForm = {
        name: task.name,
        description: task.description,
        level: task.level,
        sectionId: task.sections.length === 0 ? null : task.sections[0].id,
        order: 0,

        result: task.result,
        effect: task.effect,
        integrations: task.integrations,
        purpose: task.purpose,
        techStack: task.techStack,

        iDontKnowWhatChoose: false,

        authorId: this.user.id,
        nProjectId: task.nProjectId,

        levels: [],
      };

      this.modal_add_job_show = true;
      this.modal_add_job_mode = "edit";
    },

    async checkJobsStatuses() {
      for (let p of this.projects) {
        for (let j of p.Jobs) {
          if (
            j?.Project_Job_Statuses == null ||
            j?.Project_Job_Statuses?.length == 0
          ) {
            await createStatus({
              status: "Ожидает",
              comment: null,
              JobId: j.id,
            });
          }
        }
      }
    },

    //TODO: заменить alert на notification от Element-Plus
    async createJob() {
      for (let i in this.addJobForm) {
        if (
          i == "iDontKnowWhatChoose" ||
          (i == "sectionId" && this.addJobForm.iDontKnowWhatChoose == true)
        ) {
          continue;
        }

        if (
          this.addJobForm[i] === "" &&
          i !== "levels_array" &&
          i !== "order"
        ) {
          alert("Не все поля заполнены!");
          return;
        }
      }

      let findDifficult = this.DifficultyLevels.findIndex(
        (obj) => obj.name === this.addJobForm.level
      );
      let difficultLevelsCounter = this.DifficultyLevels[findDifficult]
        .difficult;

      let LevelsArray = [];
      if (this.addJobForm.iDontKnowWhatChoose == false) {
        let findSectionIDFromList = this.SectionsList.findIndex(
          (obj) => obj.id === this.addJobForm.sectionId
        );

        LevelsArray = this.SectionsList[findSectionIDFromList].levels;
        LevelsArray = LevelsArray.filter((num, i) => {
          if (i < difficultLevelsCounter) {
            return num;
          }
        });
      } else {
        LevelsArray = [];
        this.addJobForm.sectionId = null;
      }

      this.addJobForm.sections =
        this.addJobForm.sectionId == null ? [] : [this.addJobForm.sectionId];
      this.addJobForm.levels = LevelsArray;

      await createJob(this.addJobForm);
      this.updateProjectCards();

      this.modal_add_job_show = false;
    },
    async editJob() {
      for (let i in this.addJobForm) {
        if (
          i == "iDontKnowWhatChoose" ||
          (i == "sectionId" && this.addJobForm.iDontKnowWhatChoose == true)
        ) {
          continue;
        }

        if (this.addJobForm[i] == "" && i != "levels" && i != "order") {
          alert("Не все поля заполнены!");
          return 0;
        }
      }

      let findDifficult = this.DifficultyLevels.findIndex(
        (obj) => obj.name === this.addJobForm.level
      );
      let difficultLevelsCounter = findDifficult.difficult;

      if (this.addJobForm.iDontKnowWhatChoose == false) {
        let findSectionIDFromList = this.SectionsList.findIndex(
          (obj) => obj.id === this.addJobForm.sectionId
        );
        let LevelsArray = this.SectionsList[findSectionIDFromList].levels;
        LevelsArray = LevelsArray.filter((num, i) => {
          if (i < difficultLevelsCounter) {
            return num;
          }
        });
        this.addJobForm.levels = LevelsArray;
        this.addJobForm.sections = [this.addJobForm.sectionId];
      } else {
        this.addJobForm.sections = [null];
      }

      await updateJob(this.view_mode_selected_task_id, this.addJobForm);
      await createStatus({
        status: "Ожидает",
        comment: null,
        JobId: this.view_mode_selected_task_id,
      });
      this.updateProjectCards();

      this.modal_add_job_show = false;
    },

    async removeJobFromList(jobid) {
      await removeJob([jobid]);
      this.updateProjectCards();
    },

    showTasksList(proj_id) {
      this.view_mode = "tasks";
      this.view_mode_selected_project_id = proj_id;

      let proj = this.projects.find((obj) => {
        return obj.id === proj_id;
      });

      this.JobsList = proj?.Jobs;
      this.ViewTasksHeader = proj?.name;
    },
    correctTaskFollowersName(tasksCounter) {
      if (tasksCounter % 10 == 1 && tasksCounter != 11) {
        return "следит";
      }

      return "следят";
    },
    getTaskDifficult(DiffName) {
      let indx = this.DifficultyLevels.findIndex((obj) => {
        return obj.name === DiffName;
      });

      return indx + 1;
    },

    getJobLatestStatus(task) {
      let status_colors = {
        red: "#EB5757",
        blue: "#12AEE3",
        green: "#6FCF97",
      };

      let TaskLastStatus =
        task?.Project_Job_Statuses != null &&
        task?.Project_Job_Statuses?.length == 0
          ? "Ожидает"
          : task?.Project_Job_Statuses[task?.Project_Job_Statuses?.length - 1]
              ?.status;

      let clear_statuses = [
        {
          name: "Ожидает",
          show_name: "Ожидает модерации",
          show_color: status_colors.blue,
        },
        {
          name: "Одобрено",
          show_name: "Задача опубликована",
          show_color: status_colors.green,
        },
        {
          name: "Отклонено",
          show_name: "Есть замечания",
          show_color: status_colors.red,
        },
      ];

      switch (TaskLastStatus) {
        case "Ожидает": {
          let obj = clear_statuses.find((status) => {
            if (status.name == TaskLastStatus) {
              return true;
            }
          });

          return obj;
        }
        case "Отклонено": {
          let obj = clear_statuses.find((status) => {
            if (status.name == TaskLastStatus) {
              return true;
            }
          });

          return obj;
        }
        case "Одобрено": {
          let obj = clear_statuses.find((status) => {
            if (status.name == TaskLastStatus) {
              return true;
            }
          });

          return obj;
        }
      }
    },

    handleEditorEditChanges(delta, setTo, fromEditorName) {
      let htmlDataFromEditor = this.$refs[fromEditorName].getHTML();
      switch (setTo) {
        case "APD": {
          this.addProjectForm.description = htmlDataFromEditor;
          break;
        }
        case "EPD": {
          this.editProjectForm.updateData.description = htmlDataFromEditor;
          break;
        }
        case "AJD": {
          this.addJobForm.description = htmlDataFromEditor;
          break;
        }
      }
    },
    handleEditorEditReady(setTo, fromEditorName) {
      let dataToSet = "";
      switch (setTo) {
        case "APD": {
          dataToSet = this.addProjectForm.description;
          break;
        }
        case "EPD": {
          dataToSet = this.editProjectForm.updateData.description;
          break;
        }
        case "AJD": {
          dataToSet = this.addJobForm.description;
          break;
        }
      }
      this.$refs[fromEditorName].setHTML(dataToSet);
    },
  },
  watch: {
    "$route.query.viewID": {
      handler: function(search) {
        this.initialization();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
