<template>
  <div class="ProfileCard">
    <p class="ProfileHeading">
      {{ !isViewMode ? "Профиль" : "Вы просматриваете профиль" }}
    </p>
    <div class="ProfileInfo" v-if="user">
      <div class="ProfileBubble">
        <div class="ProfileCircle">
          <span
            class="ProfileCutName"
            v-if="
              user.url_img === null && user.name !== null && user.name !== ''
            "
            >{{ user.name[0] }}</span
          >
        </div>
      </div>

      <div class="TextBlock">
        <span class="ProfileOrgname" lang="ru">
          {{
            user.parent_company[0].name !== null
              ? user.parent_company[0].name
              : ""
          }}
        </span>
        <span class="ProfileUsername" lang="ru">
          {{ user.name !== null ? user.name : "" }}
          {{ user.surname !== null ? user.surname : "" }}
        </span>
      </div>
    </div>
    <ButtonGradientBorder
      v-if="!isViewMode"
      class="Button"
      ButtonText="Редактировать профиль"
      @click.native="editProfileHandle()"
    />
  </div>
</template>

<script>
import ButtonGradientBorder from "@/components/CustomElements/ButtonGradientBorder.vue";
export default {
  props: {
    editProfileHandle: {
      type: Function,
      default: () => {
        return () => {};
      },
    },
    user: {
      type: Object,
      default: () => {
        return () => {};
      },
    },
    isViewMode: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {};
  },
  name: "BetaProfileCard",
  components: {
    ButtonGradientBorder,
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.TextBlock {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  flex-shrink: 1;
}

.ProfileCard {
  position: relative;

  width: 100%;
  height: fit-content;

  padding: 28px 32px;
  background: rgba(32, 30, 72, 0.7);
  box-shadow: 8px 0px 8px rgba(30.6, 17.88, 35.06, 0.15);
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
}

.ProfileHeading {
  position: relative;
  display: block;

  width: fit-content;
  color: #e8e7ec;
  font-size: 20px;
  font-family: "Montserrat";
  font-weight: 600;
  word-wrap: break-word;
}
.ProfileInfo {
  width: 100%;
  height: auto;
  min-height: 78px;
  position: relative;

  display: flex;
  flex-direction: row;
  gap: 16px;
}

.ProfileInfo > * {
  margin-top: auto;
  margin-bottom: auto;
}

.ProfileUsername {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  color: #c8c3de;
  font-size: 15px;
  font-family: "Montserrat";
  font-weight: 500;
  line-height: normal;

  hyphens: auto;
}

.ProfileOrgname {
  width: fit-content;
  height: fit-content;

  position: relative;

  color: #c8c3de;
  font-size: 20px;
  font-family: "Montserrat";
  font-weight: 500;
  line-height: normal;

  hyphens: auto;
}

.ProfileCircle {
  position: relative;
  display: flex;

  width: 78px;
  height: 78px;
  background: #292558;
  border-radius: 9999px;
  border: 0.5px #6f6c99 solid;
}

.ProfileCutName {
  position: relative;
  display: block;

  margin: auto;

  width: fit-content;
  height: fit-content;

  text-align: center;
  color: white;
  font-size: 24px;
  font-family: "Montserrat";
  font-weight: 800;
}

.ProfileBubble {
  position: relative;

  width: fit-content;
  height: fit-content;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-shrink: 0;
}

.Button {
  height: auto;
  width: 100%;
}
</style>

<style scoped>
@media (max-width: 479px) {
  .ProfileCard {
    width: 362;
    height: fit-content;
    padding: 18px 21px;
    background: rgba(32, 30, 72, 0.7);
    box-shadow: 8px 0px 8px rgba(30.6, 17.88, 35.06, 0.15);
    border-radius: 16px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: inline-flex;
  }

  .ProfileUsername {
    position: relative;
    width: fit-content;
    height: fit-content;
    left: 0px;
    color: #c8c3de;
    font-size: 12px;
    font-family: "Montserrat";
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.1px;
  }

  .ProfileOrgname {
    position: relative;
    width: fit-content;
    height: fit-content;
    min-height: 0px;
    height: fit-content;
    left: 0px;
    top: 0px;
    color: #c8c3de;
    font-size: 14px;
    font-family: "Montserrat";
    font-weight: 500;
    line-height: 1.2em;
  }

  .ProfileHeading {
    width: fit-content;
    color: #e8e7ec;
    font-size: 16px;
    font-family: "Montserrat";
    font-weight: 600;
    word-wrap: break-word;
  }
  .ProfileInfo {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    position: relative;
  }

  .ProfileBubble {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  .ProfileCircle {
    width: 60px;
    height: 60px;
    left: 0px;
    top: 0px;
    position: relative;
    background: #292558;
    border-radius: 9999px;
    border: 0.5px #6f6c99 solid;
  }

  .ProfileCutName {
    width: fit-content;
    height: fit-content;
    left: 0px;
    top: 0px;
    position: relative;
    text-align: center;
    color: white;
    font-size: 16px;
    font-family: "Montserrat";
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  .Button {
    height: fit-content;
    width: 100%;
  }

  .TextBlock {
    position: relative;
    display: flex;

    flex-direction: column;
    justify-content: flex-start;

    width: 100%;
    flex-shrink: 1;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .ProfileCard {
    width: 362x;
    height: fit-content;
    padding: 24px 28px;
    background: rgba(32, 30, 72, 0.7);
    box-shadow: 8px 0px 8px rgba(30.6, 17.88, 35.06, 0.15);
    border-radius: 20px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: inline-flex;
  }
  .ProfileUsername {
    position: relative;
    width: fit-content;
    height: fit-content;
    left: 0px;
    color: #c8c3de;
    font-size: 14px;
    font-family: "Montserrat";
    font-weight: 500;
    line-height: 24px;
  }

  .ProfileOrgname {
    position: relative;
    width: fit-content;
    height: fit-content;
    min-height: 0px;
    height: fit-content;
    left: 0px;
    top: 0px;
    color: #c8c3de;
    font-size: 16px;
    font-family: "Montserrat";
    font-weight: 500;
    line-height: 1.2em;
  }

  .ProfileHeading {
    width: fit-content;
    color: #e8e7ec;
    font-size: 18px;
    font-family: "Montserrat";
    font-weight: 600;
    word-wrap: break-word;
  }
  .ProfileInfo {
    width: 100%;
    /* height: 68px;  */
    position: relative;
  }

  .ProfileBubble {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ProfileCircle {
    width: 60px;
    height: 60px;
    left: 0px;
    top: 0px;
    position: relative;
    background: #292558;
    border-radius: 9999px;
    border: 0.5px #6f6c99 solid;
  }

  .ProfileCutName {
    width: fit-content;
    height: fit-content;
    left: 0px;
    top: 0px;
    position: relative;
    text-align: center;
    color: white;
    font-size: 20px;
    font-family: "Montserrat";
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  .Button {
    height: fit-content;
    width: 100%;
  }

  .TextBlock {
    position: relative;
    display: flex;

    flex-direction: column;
    justify-content: flex-start;

    width: 100%;
    flex-shrink: 1;
  }

  /* .ButtonLink {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.7);

  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.ButtonGradient {
  width: 100% ;
  display: block;
  position: relative;

  height: auto;

  padding: 12px 24px 12px 24px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.5px;
  line-height: 22px;

  text-align: center;

  color: #ffffff;

  border-radius: 4px;
  border: 0px solid;
  backdrop-filter: blur(1px);
}
.ButtonGradient::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 5px;
  padding: 1px; 
  background: linear-gradient(221.26deg, #c548ab -3.4%, #635bff 101.08%);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
} */

  #ProfilePage {
    overflow: auto;
  }

  #ProfilePage > .ProfileContent > .ProfileBlocks {
    flex-wrap: wrap;
    padding: 0% 32px 0% 21px;

    row-gap: 40px;
    column-gap: 40px;
  }

  #ProfilePage > .ProfileContent {
    overflow: auto;
    padding: 23px 0px 23px 0px;
  }

  .BlockColumn1,
  .BlockColumn2 {
    margin-right: 0px;
    flex-shrink: 1;
  }

  .BlockColumn1 {
    flex-direction: row;
    width: 100%;
    max-width: 100%;

    height: 350px !important;

    row-gap: 40px;
    column-gap: 40px;

    order: 1;
  }

  .BlockColumn2 {
    width: 47%;
    max-width: 47%;

    height: fit-content !important;

    order: 2;
  }

  .BlockColumn3 {
    width: 47%;
    max-width: 47%;

    height: auto !important;

    padding-bottom: 21px;
    order: 3;
  }

  #ProfilePage .BlockColumn1 > *:nth-child(1) {
    height: fit-content;
    width: 362px;
    margin-bottom: 40px !important;
  }

  #ProfilePage .BlockColumn1 > *:nth-child(2) {
    width: 47% !important;
    box-shadow: none !important;
  }

  #ProfilePage .BlockColumn2 > *:nth-child(2) {
    flex-shrink: 0;
    padding-bottom: 23px;
    height: 600px;

    flex: auto;
    overflow: auto;
  }

  .BlockColumn1 > *:not(:nth-last-child(1)) {
    margin-bottom: 32px;
  }

  .BlockColumn1 > *:not(:nth-last-child(1)) {
    margin-bottom: 40px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .ProfileCard {
    width: 362x;
    height: fit-content;
    padding: 24px 28px;
    background: rgba(32, 30, 72, 0.7);
    box-shadow: 8px 0px 8px rgba(30.6, 17.88, 35.06, 0.15);
    border-radius: 20px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: inline-flex;
  }
  .ProfileUsername {
    width: fit-content;
    left: 0px;
    top: 0px;
    position: relative;
    color: #c8c3de;
    font-size: 15px;
    font-family: "Montserrat";
    font-weight: 500;
    line-height: 24px;
  }

  .ProfileOrgname {
    width: fit-content;
    height: fit-content;
    min-height: 0px;
    left: 0px;
    top: 0px;
    position: relative;
    color: #c8c3de;
    font-size: 20px;
    font-family: "Montserrat";
    font-weight: 500;
    line-height: 1.2em;
  }

  .ProfileHeading {
    width: fit-content;
    color: #e8e7ec;
    font-size: 18px;
    font-family: "Montserrat";
    font-weight: 600;
    word-wrap: break-word;
  }
  .ProfileInfo {
    width: 100%;
    position: relative;
  }

  .ProfileBubble {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ProfileCircle {
    width: 68px;
    height: 68px;
    left: 0px;
    top: 0px;
    position: relative;
    background: #292558;
    border-radius: 9999px;
    border: 0.5px #6f6c99 solid;
  }

  .ProfileCutName {
    width: fit-content;
    height: fit-content;
    left: 0px;
    top: 0px;
    position: relative;
    text-align: center;
    color: white;
    font-size: 20px;
    font-family: "Montserrat";
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  .Button {
    height: fit-content;
    width: 100%;
  }

  #ProfilePage {
    overflow: auto;
  }

  #ProfilePage > .ProfileContent > .ProfileBlocks {
    flex-wrap: wrap;
    padding: 0% 32px 0% 21px;

    row-gap: 40px;
    column-gap: 40px;
  }

  #ProfilePage > .ProfileContent {
    overflow: auto;
    padding: 23px 0px 23px 0px;
  }

  .BlockColumn1,
  .BlockColumn2 {
    margin-right: 0px;
    flex-shrink: 1;
  }

  .BlockColumn1 {
    flex-direction: row;
    width: 100%;
    max-width: 100%;

    height: 350px !important;

    row-gap: 40px;
    column-gap: 40px;

    order: 1;
  }

  .BlockColumn2 {
    width: 47%;
    max-width: 47%;

    height: fit-content !important;

    order: 2;
  }

  .BlockColumn3 {
    width: 47%;
    max-width: 47%;

    height: auto !important;

    padding-bottom: 21px;
    order: 3;
  }

  #ProfilePage .BlockColumn1 > *:nth-child(1) {
    height: fit-content;
    width: 362px;
    margin-bottom: 40px !important;
  }

  #ProfilePage .BlockColumn1 > *:nth-child(2) {
    width: 47% !important;
    box-shadow: none !important;
  }

  #ProfilePage .BlockColumn2 > *:nth-child(2) {
    flex-shrink: 0;
    padding-bottom: 23px;
    height: 600px;

    flex: auto;
    overflow: auto;
  }

  .BlockColumn1 > *:not(:nth-last-child(1)) {
    margin-bottom: 32px;
  }

  .BlockColumn1 > *:not(:nth-last-child(1)) {
    margin-bottom: 40px;
  }

  .TextBlock {
    position: relative;
    display: flex;

    flex-direction: column;
    justify-content: flex-start;

    width: 100%;
    flex-shrink: 1;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .ProfileCard {
    width: 100%;
    height: fit-content;
    padding: 24px 28px;
    background: rgba(32, 30, 72, 0.7);
    box-shadow: 8px 0px 8px rgba(30.6, 17.88, 35.06, 0.15);
    border-radius: 20px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: inline-flex;
  }
  .ProfileUsername {
    width: fit-content;
    left: 0px;
    top: 0px;
    position: relative;
    color: #c8c3de;
    font-size: 15px;
    font-family: "Montserrat";
    font-weight: 500;
    line-height: 24px;
  }

  .ProfileOrgname {
    width: fit-content;
    min-height: 0px;
    height: fit-content;
    left: 0px;
    top: 0px;
    position: relative;
    color: #c8c3de;
    font-size: 20px;
    font-family: "Montserrat";
    font-weight: 500;
    line-height: 1.2em;
  }

  .Button {
    height: fit-content;
    width: 100%;
  }

  .TextBlock {
    position: relative;
    display: flex;

    flex-direction: column;
    justify-content: flex-start;

    width: 100%;
    flex-shrink: 1;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .ProfileCard {
    width: 398px;
    height: fit-content;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 28px;
    padding-bottom: 28px;
    background: rgba(32, 30, 72, 0.7);
    box-shadow: 8px 0px 8px rgba(30.6, 17.88, 35.06, 0.15);
    border-radius: 20px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: inline-flex;
  }
}

@media (min-width: 1600px) {
  .ProfileCard {
    width: 398px;
    height: fit-content;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 28px;
    padding-bottom: 28px;
    background: rgba(32, 30, 72, 0.7);
    box-shadow: 8px 0px 8px rgba(30.6, 17.88, 35.06, 0.15);
    border-radius: 20px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: inline-flex;
  }
}
</style>
