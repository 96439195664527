import request from "@/api/config.js";

// export function getTemplate(id) {
//   return request({
//     url: `department/${id}`,
//     method: 'get'
//   })
// }

export function getTemplate() {
  return request({
    url: `skill/global/department`,
    method: "get",
  });
}

export function getUser(id) {
  return request({
    url: `user/${id}`,
    method: "get",
  });
}

export function addSectorTemplate(data) {
  return request({
    url: `sector_create`,
    method: "post",
    data,
  });
}

export function addSectionTemplate(data) {
  return request({
    url: `section_create`,
    method: "post",
    data,
  });
}

export function addLevelTemplate(data) {
  return request({
    url: `level_create`,
    method: "post",
    data,
  });
}

export function updateUser(id, data) {
  return request({
    url: `user/${id}`,
    method: "put",
    data,
  });
}

export function updateSector(id, data) {
  return request({
    url: `sectors/${id}`,
    method: "put",
    data,
  });
}

export function updateSection(id, data) {
  return request({
    url: `section/${id}`,
    method: "put",
    data,
  });
}

export function updateCell(data) {
  return request({
    url: `update_status_cell`,
    method: "post",
    data,
  });
}

export function deleteSectorTemplate(id) {
  return request({
    url: `sector_delete/${id}`,
    method: "delete",
  });
}

export function deleteSectionTemplate(id) {
  return request({
    url: `section_delete/${id}`,
    method: "delete",
  });
}

export function deleteLevelTemplate(id) {
  return request({
    url: `level_delete/${id}`,
    method: "delete",
  });
}

export function getSectors() {
  return request({
    url: `sectors`,
    method: "get",
  });
}

export function getSection(id) {
  return request({
    url: `section/${id}`,
    method: "get",
  });
}

export function getSections() {
  return request({
    url: `sections`,
    method: "get",
  });
}

export function getSectorsWithSections() {
  return request({
    url: `sectors-with-sections`,
    method: "get",
  });
}

export function getSectorsWithSectionsArchieved() {
  return request({
    url: `sectors-with-sections-archieved`,
    method: "get",
  });
}

export function getLevels() {
  return request({
    url: `levels`,
    method: "get",
  });
}

export function getLevel(id) {
  return request({
    url: `level/${id}`,
    method: "get",
  });
}

export function updateLevel(id, data) {
  return request({
    url: `level/${id}`,
    method: "put",
    data,
  });
}
