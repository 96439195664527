<template>
  <div class="SearchInput">
    <div class="inputIcon"></div>
    <input
      type="text"
      v-model="SearchText"
      :placeholder="placeholder"
      @input="
        () => {
          postFiltersDataChanges(SearchText);
          sortAndFilterArray();
        }
      "
    />
  </div>
</template>

<script>
import Fuse from "fuse.js";

export default {
  name: "SearchInput",
  props: {
    placeholder: {
      type: String,
      default: "Поиск",
    },
    searchAttribures: {
      /*
        ^аттрибуты по которым производится поиск

        ex.: ["name", "desc", "price"]
       */
      type: Array,
      default: () => [],
    },
    originalArray: {
      /*
        ^Массив по которому производится поиск

        ex.: [{text: "123", id: 1....]
       */
      type: Array,
      default: () => [],
      required: true,
    },
    setNewArrayCallback: {
      /*
        ^Функция обратного вызова, для возврата сортированного и/или фильтрованного массива

        ex.: см. client\src\views\Profile\Octastore.vue функция "handleSearchFilterSortingObject"
       */
      type: Function,
      default: () => {},
      required: true,
    },
    searchTextVal: {
      type: String,
      default: "",
    },
    postFiltersDataChanges: {
      /*
        ^Отправляем данные фильтров & поиска & сортировки в родителя,
        ^   чтобы при перерисовке компонента не происходило сброса параметров

        ?Отправляет в родителя объект, следующего вида:
        ?   {button: FilterSelected, search: SearchText, sorting: SortSelected}
       */
      type: Function,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      SearchText: "",
    };
  },
  components: {},
  created() {
    if (this.searchTextVal != null) {
      this.SearchText = this.searchTextVal;
    }

    this.sortAndFilterArray();
  },
  methods: {
    sortAndFilterArray() {
      let data = [...this.originalArray];
      let result = [];

      if (this.SearchText === "") {
        this.setNewArrayCallback(data);
        return 0;
      }

      // Опции для настройки поиска
      const options = {
        isCaseSensitive: false,
        includeScore: false,
        shouldSort: false,
        threshold: 0.25,
        keys: this.searchAttribures, // Поля, по которым будет производиться поиск
      };

      // Инициализация экземпляра fuse
      const fuse = new Fuse(data, options);

      // Выполнение поиска
      result = fuse.search(this.SearchText);
      result = result.map((x) => x.item);

      // Вывод результатов
      this.setNewArrayCallback(result);
    },
  },
};
</script>

<style scoped>
.SearchInput {
  position: relative;
  display: block;

  width: auto;
  height: fit-content;
}

.SearchInput > input {
  position: relative;

  width: 100%;
  min-width: 50px;
  height: fit-content;

  padding: 12px;
  padding-left: 36px;
  padding-right: 8px;

  border-radius: 4px;
  border: 1px solid #656bff80;
  background-color: transparent;
  /* backdrop-filter: blur(12px); */

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #ffffffe5;

  outline: none;
}
.SearchInput > input:focus {
  border-color: #656bff;
}

.SearchInput > input::placeholder,
.SearchInput > input::-ms-input-placeholder {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #d3caff73;
}
.inputIcon {
  position: absolute;
  display: block;

  top: calc(50% - (24px / 2));
  left: 8px;

  width: 24px;
  height: 24px;

  background: url("./../../assets/img/ReDesign/interface_icons/search_input_icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 18px;
}
</style>
