<template>
  <div class="Card">
    <div class="Content">
      <div class="Header">
        <!-- <span class="status" v-if="project.Jobs.length===0">В проекте нет задач</span> -->

        <div class="ContainStatus noselect">
          <span
            class="status"
            :style="
              `border-color: ${getLatestStatus.show_color}; color: ${getLatestStatus.show_color};`
            "
            >{{ getLatestStatus.show_name }}</span
          >
          <span
            class="HaveProjectComments"
            v-if="getLatestStatus.name == 'Отклонено'"
            @click="show_project_comment = true"
            >Посмотреть замечания</span
          >
        </div>

        <div class="HeaderButtons">
          <template v-if="!isViewMode">
            <div class="HButton HBSettings" @click="editProjectHandle()"></div>
            <div
              class="HButton HBTrash"
              @click="removeProjectHandle(project?.id)"
            ></div>
          </template>
        </div>
      </div>
      <div class="PhotoTitleDirections">
        <!-- <div class="Photo"></div> -->
        <div class="ContainTitleDirections">
          <span class="title">{{ project.name }}</span>
          <div class="directionContainer">
            <span
              class="direction"
              v-for="(text, indx) in getDirectionsList"
              :key="`${text}${indx}`"
              >{{ text }}</span
            >
          </div>

          <!-- <span class="ProjectFollowers">
            <div class="CTDFollowersIcon"></div>
            <span>12 следят</span>
          </span> -->
        </div>
      </div>
      <div class="ButtonsBlock">
        <ButtonGradient
          v-if="project?.Jobs?.length > 0"
          @click.native="viewTasksListHandle(project?.id)"
          ButtonText="Перейти к задачам"
        />
        <ButtonGradientBorder
          v-if="!isViewMode"
          @click="addJobHandle(project?.id)"
          ButtonText="Добавить задачу в проект"
        />
      </div>

      <!-- <div class="ContactInfo">
        <span class="CInfoItem">
          <div class="CInfoIcon EmailIcon"></div>
          <span class="CInfoText">test@example.com</span>
        </span>
        <span class="CInfoItem">
          <div class="CInfoIcon PhoneIcon"></div>
          <span class="CInfoText">79000000000</span>
        </span>
      </div> -->
    </div>

    <el-dialog
      v-model="show_project_comment"
      title="Замечания от модератора"
      custom-class="ORG_VIEW_COMMENT_DIALOG"
    >
      <span class="DEFAULT_ORG_VIEW_TEXT">{{
        project.Project_Job_Statuses[project.Project_Job_Statuses.length - 1]
          .comment
      }}</span>
      <template #footer>
        <template v-if="!isViewMode">
          <span
            class="DEFAULT_ORG_VIEW_EDIT_BUTTON noselect"
            @click="
              show_project_comment = false;
              editProjectHandle();
            "
            >Редактировать проект</span
          >
          <span
            class="DEFAULT_ORG_VIEW_CLOSE_BUTTON noselect"
            @click="show_project_comment = false"
            >Изменю позже</span
          >
        </template>
        <template v-else>
          <span
            class="DEFAULT_ORG_VIEW_EDIT_BUTTON noselect"
            @click="show_project_comment = false"
            >Закрыть</span
          >
        </template>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { createStatus } from "@/api/project_job_status.js";

import ButtonGradientBorder from "@/components/CustomElements/ButtonGradientBorder.vue";
import ButtonGradient from "@/components/CustomElements/ButtonGradient.vue";

export default {
  name: "BetaProjectCard",
  props: {
    project: {
      type: Object,
      default: () => {
        return () => {};
      },
    },
    removeProjectHandle: {
      type: Function,
      default: () => {
        return () => {};
      },
    },
    editProjectHandle: {
      type: Function,
      default: () => {
        return () => {};
      },
    },
    addJobHandle: {
      type: Function,
      default: () => {
        return () => {};
      },
    },
    viewTasksListHandle: {
      type: Function,
      default: () => {
        return () => {};
      },
    },
    isViewMode: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      show_project_comment: false,
    };
  },
  async created() {
    if (
      this.project?.Project_Job_Statuses != null &&
      this.project?.Project_Job_Statuses?.length == 0
    ) {
      await createStatus({
        status: "Ожидает",
        comment: null,
        nProjectId: this.project.id,
      });
    }
  },
  components: { ButtonGradientBorder, ButtonGradient },
  methods: {
    addJob() {},

    getRightText(number) {
      if (number % 10 == 1 && number != 11) {
        return "замечание по задаче";
      }
      if (
        number % 10 >= 2 &&
        number % 10 <= 4 &&
        (number <= 11 || number >= 15)
      ) {
        return "замечания по задачам";
      }

      return "замечаний по задачам";
    },
  },
  computed: {
    getDirectionsList() {
      let DirectionsList = [];
      if (this.project?.Jobs != null && this.project?.Jobs?.length != 0) {
        for (let i of this.project.Jobs) {
          if (i?.section?.name == null) {
            continue;
          }

          let f = DirectionsList.find((text) => {
            if (text === i?.section?.name) {
              return true;
            }
          });

          if (f == null) {
            DirectionsList.push(i?.section?.name);
          }
        }
      }

      return DirectionsList;
    },

    getLatestStatus() {
      let status_colors = {
        red: "#EB5757",
        blue: "#12AEE3",
        green: "#6FCF97",
      };

      let ProjLastStatus =
        this.project?.Project_Job_Statuses != null &&
        this.project?.Project_Job_Statuses?.length == 0
          ? "Ожидает"
          : this.project?.Project_Job_Statuses[
              this.project?.Project_Job_Statuses?.length - 1
            ]?.status;

      let jobs_counter = this.project?.Jobs?.length;
      let JobsWithComments = 0;

      for (let i of this.project?.Jobs) {
        let status =
          i?.Project_Job_Statuses != null &&
          i?.Project_Job_Statuses?.length == 0
            ? "Ожидает"
            : i?.Project_Job_Statuses[i?.Project_Job_Statuses?.length - 1]
                ?.status;

        if (status == "Отклонено") {
          JobsWithComments++;
        }
      }

      let clear_statuses = [
        {
          name: "Ожидает",
          show_name: "Ожидает модерации",
          show_color: status_colors.blue,
        },
        {
          name: "Одобрено",
          show_name: "Проект на стадии разработки",
          show_color: status_colors.green,
        },
        {
          name: "Отклонено",
          show_name: "Есть замечания по проекту",
          show_color: status_colors.red,
        },
        {
          name: "#NoJobs",
          show_name: "Нет задач в проекте",
          show_color: status_colors.red,
        },
        {
          name: "#JobsWithComments",
          show_name: `Есть ${JobsWithComments} ${this.getRightText(
            JobsWithComments
          )}`,
          show_color: status_colors.red,
        },
      ];

      switch (ProjLastStatus) {
        case "Ожидает": {
          let obj = clear_statuses.find((status) => {
            if (status.name == ProjLastStatus) {
              return true;
            }
          });

          return obj;
        }
        case "Отклонено": {
          let obj = clear_statuses.find((status) => {
            if (status.name == ProjLastStatus) {
              return true;
            }
          });

          return obj;
        }
        case "Одобрено": {
          if (jobs_counter > 0) {
            if (JobsWithComments > 0) {
              let obj = clear_statuses.find((status) => {
                if (status.name == "#JobsWithComments") {
                  return true;
                }
              });

              return obj;
            } else {
              let obj = clear_statuses.find((status) => {
                if (status.name == "Одобрено") {
                  return true;
                }
              });

              return obj;
            }
          } else {
            let obj = clear_statuses.find((status) => {
              if (status.name == "#NoJobs") {
                return true;
              }
            });

            return obj;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.ContainStatus > * {
  margin-top: auto;
  margin-bottom: auto;
}
.ContainStatus {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}

.ContainStatus > .HaveProjectComments {
  position: relative;
  display: block;

  cursor: pointer;

  width: fit-content;
  height: fit-content;

  color: #eb5757;
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

.ButtonsBlock {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;

  margin-top: auto;

  width: 100%;
  height: fit-content;

  flex-wrap: wrap;
}
.ContactInfo {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 24px;
}
.ContactInfo > .CInfoItem {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
}
.ContactInfo > .CInfoItem > * {
  margin-top: auto;
  margin-bottom: auto;
}
.ContactInfo > .CInfoItem > .CInfoIcon {
  position: relative;
  display: block;

  width: 14px;
  height: 14px;
}
.ContactInfo > .CInfoItem > .CInfoText {
  color: #c8c3de;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 23.25px */
}

.CInfoIcon.EmailIcon {
  background: url("./../../assets/img/profile/OrganizationProjectCardEmail.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.CInfoIcon.PhoneIcon {
  background: url("./../../assets/img/profile/OrganizationProjectCardPhone.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.ProjectFollowers > * {
  margin-top: auto;
  margin-bottom: auto;
}
.ProjectFollowers {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;

  color: #c8c3de;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%;
}
.CTDFollowersIcon {
  position: relative;

  width: 16px;
  height: 12px;

  background: url("./../../assets/img/profile/OrganizationProjectCardPeoples.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.HeaderButtons {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
}
.HeaderButtons > .HButton {
  position: relative;

  cursor: pointer;

  width: 1.3125rem;
  height: 1.3125rem;
}
.HeaderButtons > .HButton.HBSettings {
  background: url("./../../assets/img/profile/edit_icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.HeaderButtons > .HButton.HBTrash {
  background: url("./../../assets/img/profile/delete_icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.Desc {
  color: #c8c3de;

  /* text */
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 23.25px */
}
.PhotoTitleDirections > * {
  margin-bottom: auto;
}
.directionContainer {
  margin: 0.5rem 0;
}
.ContainTitleDirections > .directionContainer > .direction {
  position: relative;

  color: #ffffff;
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  padding: 4px 8px;

  border-radius: 4px;
  background: #1a1a3d;
}
.PhotoTitleDirections > .ContainTitleDirections > .directionContainer {
  position: relative;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;

  width: 100%;
  height: fit-content;

  flex-shrink: 1;
}
.PhotoTitleDirections > .ContainTitleDirections > .title {
  color: #e8e7ec;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  flex-shrink: 1;
}
.PhotoTitleDirections > .ContainTitleDirections {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
}
.PhotoTitleDirections > .Photo {
  position: relative;

  width: 110px;
  height: 110px;

  background: url("https://images.unsplash.com/photo-1489782419474-4d4221dc5b10?ixid=M3wxMTI1OHwwfDF8cmFuZG9tfHx8fHx8fHx8MTY5Mzc5NTIyNHw&ixlib=rb-4.0.3&q=85&w=1920");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  flex-shrink: 0;

  border-radius: 16px;
}
.PhotoTitleDirections {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
}
.Card {
  position: relative;

  padding: 30px;

  width: fit-content;

  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 32px;

  border-radius: 20px;
  background: rgba(32, 30, 72, 0.7);
  /* shadow */
  box-shadow: 8px 0px 8px 0px rgba(31, 18, 35, 0.15);
}

.Card > .Buttons {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.Card > .Buttons > .Left {
  position: relative;

  width: fit-content;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
}

.Card > .Content {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  width: 100%;
  height: 100%;
}

.Header {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.status {
  position: relative;

  padding: 4px 8px;
  margin-right: 1rem;

  width: fit-content;
  height: fit-content;
  max-height: 300px;

  color: #12aee3;
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  border-radius: 4px;
  border: 1px solid #12aee3;
}
</style>

<style scoped>
@media (min-width: 1200px) and (max-width: 1399px) {
}
</style>
