import request from "@/api/config.js";

export function createStatus(data) {
  return request({
    url: `project-job-status/create`,
    method: "post",
    data,
  });
}

export function getAll() {
  return request({
    url: `project-job-status/get-all`,
    method: "get",
  });
}

export function findByID(id) {
  return request({
    url: `project-job-status/${id}`,
    method: "get",
  });
}
export function findStatutesForProject(id) {
  return request({
    url: `project-job-status/get-all/nproject/${id}`,
    method: "get",
  });
}

export function findStatutesForJob(id) {
  return request({
    url: `project-job-status/get-all/job/${id}`,
    method: "get",
  });
}
